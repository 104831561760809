<template>
    <div class="flex pb-[20px]">
        <div class="w-full">
            <div class="pb-[30px] w-max mx-auto">

                <div class="balances-width px-4 flex justify-between">
                    <!-- <div class="px-4 mt-3 flex flex-col sm:flex-row sm:flex-wrap sm:justify-between "> -->
                    <div class="p-4 w-1/4">
                        <!-- <div class="p-4 mb-4 sm:mb-0 sm:w-1/4"> -->
                        <balance-card
                            :loading="balancesLoading"
                            :balance="cashboxBalance + terminalBalanceForRange + investBalance"
                            :title="'всі джерела'"
                            :valueColor="'#4A4E69'"
                        />
                    </div>
                    <div class="p-4 w-1/4">
                        <balance-card 
                            :loading="balancesLoading"
                            :balance="cashboxBalance"
                            :title="'готівка'"
                            :valueColor="'#4A4E69'"
                        />
                    </div>
                    <div class="p-4 w-1/4 relative">
                        <balance-card
                            @click="showDetailCashless = true" 
                            class="cursor-pointer select-none"
                            :loading="balancesLoading"
                            :balance="terminalBalanceForRange"
                            :title="'термінал'"
                            :valueColor="'#4A4E69'"
                        />
                    </div>
                    <div class="p-4 w-1/4">
                        <balance-card 
                            :loading="balancesLoading"
                            :balance="investBalance"
                            :title="'інвестиції'"
                            :valueColor="'#4A4E69'"
                        />
                    </div>
                </div>

                <div class="px-[35px] flex justify-between">
                    <div>АНАЛІТИКА</div>
                    <div>
                        <!---<select v-model="selectedDay" name="years" class="outline-none border-[#A4A6B3] text-gray-emb border-[1px] bg-transparent text-[13px] flex items-center justify-start px-[5px] py-[0.6px] cursor-pointer rounded-[4px] w-[65px] text-center ">
                            <option v-for="day in daysOptions" class="bg-[#FFFFFF]" :value="day.value">{{ day.text }}</option>
                        </select>-->

                        <div class="flex items center gap-3">
                            <select v-model="selectedStartDay" name="years" class="outline-none border-[#A4A6B3] text-gray-emb border-[1px] bg-transparent text-[13px] flex items-center justify-start px-[5px] py-[0.6px] cursor-pointer rounded-[4px] w-[65px] text-center ">
                                <option v-for="day in daysOptions" class="bg-[#FFFFFF]" :value="day.value">{{ day.text }}</option>
                            </select>
                            <span> - </span>
                            <select v-model="selectedEndDay" name="years" class="outline-none border-[#A4A6B3] text-gray-emb border-[1px] bg-transparent text-[13px] flex items-center justify-start px-[5px] py-[0.6px] cursor-pointer rounded-[4px] w-[65px] text-center ">
                                <option v-for="day in daysOptions" class="bg-[#FFFFFF]" :value="day.value">{{ day.text }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="flex pl-[35px]">
                    <div class="w-[50%]">

                        <div class="lowercase text-[12px] text-center my-[20px]">продано абонементів ЗА ПЕРІОД</div>

                        <div v-for="group in tarriffs" class="mt-[22px]">
                            <div v-for="tarriff in group.tariffs" class="flex items-center mt-[3px]">
                                <div class="truncate w-[20%]">{{ group.group_name }} ({{ tarriff.tariff_training_count }})</div>
                                <div class="w-[80%] h-[16px] flex items-center">
                                    <div 
                                        class="h-full" 
                                        :style="[
                                            'width: ' + (countPercent(countSaleTarriffsInDayByID(tarriff.tariff_id), maxTarriffsSaleForRange) > 0 ? countPercent(countSaleTarriffsInDayByID(tarriff.tariff_id), maxTarriffsSaleForRange) : 1) + '%',
                                            'background-color: ' + tarriff.tariff_color
                                            ]"></div>
                                    <div class="ml-[5px]">{{ countSaleTarriffsInDayByID(tarriff.tariff_id) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="w-[50%] px-[45px]">
                        <div>
                            <div class="lowercase text-[12px] text-center my-[20px]">ДОХІД ЗА ПЕРІОД В РОЗРІЗІ ДЖЕРЕЛ</div>
                            <div v-if="!balancesLoading" class="h-[150px] flex justify-center gap-6">
                                <div class="h-full flex flex-col items-center justify-end">
                                    <div class="text-[12px]">{{ selectedRangeMoneyStats.invest }}</div>
                                    <div class="w-[50px] bg-green-emb min-h-[5px]" :style="['height: ' + countPercent(selectedRangeMoneyStats.invest, selectedRangeMoneyStats.total) + '%']"></div>
                                    <div class="text-[14px]">інвестиції</div>
                                </div>
                                <div class="h-full flex flex-col items-center justify-end">
                                    <div class="text-[12px]">{{ selectedRangeMoneyStats.cash }}</div>
                                    <div class="w-[50px] bg-green-emb min-h-[5px]" :style="['height: ' + countPercent(selectedRangeMoneyStats.cash, selectedRangeMoneyStats.total) + '%']"></div>
                                    <div class="text-[14px]">готівка</div>
                                </div>
                                <div class="h-full flex flex-col items-center justify-end">
                                    <div class="text-[12px]">{{ selectedRangeMoneyStats.cashless }}</div>
                                    <div class="w-[50px] bg-green-emb min-h-[5px]" :style="['height: ' + countPercent(selectedRangeMoneyStats.cashless, selectedRangeMoneyStats.total) + '%']"></div>
                                    <div class="text-[14px]">безготівка</div>
                                </div>
                                <div class="h-full flex flex-col items-center justify-end">
                                    <div class="text-[12px]">{{ selectedRangeMoneyStats.total }}</div>
                                    <div class="w-[50px] bg-green-emb min-h-[5px]" :style="['height: ' + countPercent(selectedRangeMoneyStats.total, selectedRangeMoneyStats.total) + '%']"></div>
                                    <div class="text-[14px]">всього</div>
                                </div>
                            </div>
                            <div v-else class="h-[150px] relative">
                                <div class="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                                    <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#990D35] border-t-transparent"></div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="lowercase text-[12px] text-center my-[20px]">КЛІЄНТИ З АБОНЕМЕНТАМИ, ЩО ЗАКІНЧУЮТЬСЯ ЗА 14 ДНІВ</div>
                            <div class="flex flex-col gap-3 h-[300px] overflow-y-auto px-[20px]">
                                <div v-for="client in clientsWithExpiringTariffs" class="flex justify-between items-center gap-3">
                                    <div class="flex flex-col">
                                        <div class="text-[14px]"><b>{{ client.client.name + ' ' + client.client.surname }}</b> ({{ client.client.phone }})</div>
                                    </div>
                                    <div class="flex flex-col">
                                        <div class="text-[14px]">{{ client.tariff.tarrif_group_name }} ({{ client.tariff.date_expired.split('T')[0].split('-').reverse().join('.') }})</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { getBalanceState, getAdmin, getTerminalTransactionsByDayAndManagerId, getClientWithTariffsExpiringIn14Days, getIncomeOnInvestBalanceManager, getAllTariffsAndGroups } from '@/services/apiRequests'
    import BalanceCard from './components/BalanceCard.vue'

    export default {
        name: 'CashierDashboard',

        setup() {
            const cashboxBalance = ref(0)
            const wayforpayBalance = ref(0)
            const bankBalance = ref(0)
            const investBalance = ref(0)

            const terminalBalanceForDay = ref(0)

            const trainersStudentsBalances = ref(0)
            const balancesLoading = ref(true)
            const showDetailCashless = ref(false)

            const daysOptions = ref([])

            const selectedDay = ref(new Date().getDate())
            const selectedDayTransactions = ref([])

            const tarriffs = ref([])

            const maxTarriffsSaleForDay = ref(0)
            const allTransactionsForCurrentDayRef = ref([])

            const clientsWithExpiringTariffs = ref([])

            const selectedDayMoneyStats = ref({
                cash: 0,
                cashless: 0,
                total: 0,
                invest: 0
            })

            const countPercent = (value, total) => {
                return ((value / total) * 100) > 0 ? ((value / total) * 100) : 0 
            }

            const countSaleTarriffsInDayByID = (tarriffId) => {
                return allTransactionsForCurrentRangeRef.value.reduce((acc, transaction) => transaction.tariff_id == tarriffId ? acc + 1 : acc, 0)
            }

            const selectedStartDay = ref(1);
            const selectedEndDay = ref(new Date().getDate());
            const allTransactionsForCurrentRangeRef = ref([]);
            const selectedRangeTransactions = ref([]);
            const selectedRangeMoneyStats = ref({
                cash: 0,
                cashless: 0,
                total: 0,
                invest: 0
            });
            const terminalBalanceForRange = ref(0);
            const maxTarriffsSaleForRange = ref(0);

            watch([selectedStartDay, selectedEndDay], async ([startDay, endDay]) => {
                balancesLoading.value = true;
                console.log('Selected range:', startDay, 'to', endDay);

                const currentAdmin = (await getAdmin()).data;
                console.log('currentAdmin', currentAdmin);

                const cashboxBalanceRes = await getBalanceState(currentAdmin.cash_balance_id);
                cashboxBalance.value = cashboxBalanceRes;

                const investBalanceRes = await getBalanceState(currentAdmin.invest_balance_id);
                investBalance.value = investBalanceRes;

                const startDate = new Date(new Date().getFullYear(), new Date().getMonth(), startDay + 1);
                const endDate = new Date(new Date().getFullYear(), new Date().getMonth(), endDay + 1);
                
                const allTransactionsForRange = [];
                for (let day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
                    const dayTransactions = (await getTerminalTransactionsByDayAndManagerId(day.toISOString(), currentAdmin.admin_id)).data;
                    allTransactionsForRange.push(...dayTransactions);
                }
                console.log('allTransactionsForRange', allTransactionsForRange);
                allTransactionsForCurrentRangeRef.value = allTransactionsForRange;

                terminalBalanceForRange.value = allTransactionsForRange.reduce((acc, transaction) => 
                    transaction.income_type == 'terminal' ? acc + transaction.amount : acc, 0
                );

                daysOptions.value = generateDaysInMonth(new Date().getMonth() + 1, new Date().getFullYear());

                selectedRangeTransactions.value = allTransactionsForRange;

                const investIncomesRange = (await getIncomeOnInvestBalanceManager(currentAdmin.invest_balance_id, startDate.toISOString(), endDate.toISOString())).data;

                selectedRangeMoneyStats.value = allTransactionsForRange.reduce((acc, transaction) => {
                    if (transaction.income_type == 'cash') {
                        acc.cash += transaction.amount;
                    } else {
                        acc.cashless += transaction.amount;
                    }
                    acc.total += transaction.amount;
                    return acc;
                }, { cash: 0, cashless: 0, total: 0 });
                
                selectedRangeMoneyStats.value.invest = investIncomesRange.transactions;
                selectedRangeMoneyStats.value.total += investIncomesRange.transactions;

                tarriffs.value = (await getAllTariffsAndGroups()).data;
                
                maxTarriffsSaleForRange.value = allTransactionsForRange.length;

                balancesLoading.value = false;
            });


            const generateDaysInMonth = (month, year) => {
                const daysInMonth = new Date(year, month, 0).getDate()
                return Array.from({ length: daysInMonth }, (day, i) => {
                    return {
                        value: i + 1,
                        text: (i + 1 < 10 ? `0${i + 1}` : i + 1) + `.${month < 10 ? `0${month}` : month}`
                    }
                })
            }

            onMounted(async () => {
                const currentAdmin = (await getAdmin()).data
                console.log('currentAdmin', currentAdmin)
                const cashboxBalanceRes = await getBalanceState(currentAdmin.cash_balance_id)
                cashboxBalance.value = cashboxBalanceRes
                const investBalanceRes = await getBalanceState(currentAdmin.invest_balance_id)
                investBalance.value = investBalanceRes

                const allTransactionsForCurrentDay = (await getTerminalTransactionsByDayAndManagerId(new Date().toISOString(), currentAdmin.admin_id)).data
                allTransactionsForCurrentDayRef.value = allTransactionsForCurrentDay

                terminalBalanceForDay.value = allTransactionsForCurrentDay.reduce((acc, transaction) => transaction.income_type == 'terminal' ? acc + transaction.amount : acc, 0)

                daysOptions.value = generateDaysInMonth(new Date().getMonth() + 1, new Date().getFullYear())

                const selectedDayDate = new Date(new Date().getFullYear(), new Date().getMonth(), selectedDay.value)

                selectedDayTransactions.value = allTransactionsForCurrentDay

                const investIncomes = (await getIncomeOnInvestBalanceManager(currentAdmin.invest_balance_id, new Date().toISOString())).data

                selectedDayMoneyStats.value = allTransactionsForCurrentDay.reduce((acc, transaction) => {
                    if (transaction.income_type == 'cash') {
                        acc.cash += transaction.amount
                    } else {
                        acc.cashless += transaction.amount
                    }
                    acc.total += transaction.amount
                    return acc
                }, { cash: 0, cashless: 0, total: 0 })
                selectedDayMoneyStats.value.invest = investIncomes.transactions
                selectedDayMoneyStats.value.total += investIncomes.transactions

                tarriffs.value = (await getAllTariffsAndGroups()).data
                
                maxTarriffsSaleForDay.value = allTransactionsForCurrentDay.length

                clientsWithExpiringTariffs.value = (await getClientWithTariffsExpiringIn14Days()).data
                console.log('clientsWithExpiringTariffs', clientsWithExpiringTariffs.value)

                selectedEndDay.value = new Date().getDate()
                selectedStartDay.value = selectedEndDay.value - 1
                

                balancesLoading.value = false
            })

            return {
                cashboxBalance,
                wayforpayBalance,
                bankBalance,
                investBalance,
                trainersStudentsBalances,
                balancesLoading,
                showDetailCashless,
                terminalBalanceForDay,
                generateDaysInMonth,
                daysOptions,
                selectedDay,
                selectedDayTransactions,
                selectedDayMoneyStats,
                countPercent,
                tarriffs,
                countSaleTarriffsInDayByID,
                maxTarriffsSaleForDay,
                clientsWithExpiringTariffs,
                selectedStartDay,
                selectedEndDay,
                selectedRangeMoneyStats,
                maxTarriffsSaleForRange,
                terminalBalanceForRange
            }
        },

        components: {
            BalanceCard
        },
    }
</script>

<style lang="scss" scoped>

</style>