<template>
    <DeleteTariffForm v-if="tariffDeletionFlag" :tariffId="tariffForDelete" @acceptDeleteTariff="deleteTarriff" @close="tariffDeletionFlag = false" />
    <div class="bg-white2 border-rose-emb w-[400px] h-[181px] rounded-[10px] pt-[10px] border-[1px]">
        <div class="bg-rose-emb text-gray-emb text-[13px] font-medium text-center rounded-[4px] mx-[14px] py-[1.2px]">
            АБОНЕМЕНТИ
        </div>
        <carousel class=" select-none" :key="sliderKey" :items-to-show="1" :wrapAround="false" :navigationEnabled="true">
            <slide 
                v-for="tariff in clientTariffs"
                :key="1" 
                :class="{
                    'pl-[60px]' : screenWidth < 1360
                }"    
            >
                <div class="ml-[-20px]">
                    <div class="leading-[0] relative top-[15px] text-red-atten text-[12px]">залишок тренувань — <b>{{ tariff.training_remains }}</b></div>
                    <TariffCard class="scale-75 relative top-[10px]" :tariff="tariff" @update-date="newDate => updateTarriffDate(tariff.client_tariff_id, newDate)"/>
                    <div>
                        <img @click="() => {tariffForDelete = tariff.client_tariff_id; tariffDeletionFlag = true}" src="@/assets/icons/trash_icon.svg" class="cursor-pointer relative left-[-20px] top-[-30px]">
                    </div>
                </div>
            </slide>

            <template #addons>
                <navigation />
            </template>
        </carousel>
    </div>
</template>

<script>
    import 'vue3-carousel/dist/carousel.css'
    import { getAllClientTariffsByClientId, deleteClientTariffById, changeDateInClientTariff } from '@/services/apiRequests'
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
    import { ref, onMounted, onBeforeMount, watch } from 'vue'
    import CircleProgress from "vue3-circle-progress"
    import TrainingCalendar from '../ui/TrainingCalendar/TrainingCalendar.vue'
    import TariffCard from './TariffCard.vue'
    import DeleteTariffForm from './DeleteTariffForm.vue'

    export default {
        name: 'stats-and-finance-card',
        props: {
            trainingsAttendedList: {
                type: Array,
                default: () => []
            },
            trainingsMissedList: {
                type: Array,
                default: () => []
            },
            groupId: {
                type: String,
                required: true
            },
            clientId: {
                type: String,
                required: true
            }
        },

        setup(props) {
            const percentTrainingsAttended = ref(0)
            const trainingsPlanedList = ref([])
            const screenWidth = ref(window.innerWidth)

            const sliderKey = ref(new Date().getTime())

            const clientTariffs = ref([])

            const tariffDeletionFlag = ref(false)
            const tariffForDelete = ref({})

            const updateTarriffDate = async (tariffId, date) => {
                console.log('tariffId, date', tariffId, new Date(date).toISOString())

                await changeDateInClientTariff(tariffId, new Date(date).toISOString())
            }

            onMounted(async () => {
                percentTrainingsAttended.value = props.trainingsAttendedList.length * 100 / (props.trainingsAttendedList.length + props.trainingsMissedList.length)
                percentTrainingsAttended.value = percentTrainingsAttended.value ? percentTrainingsAttended.value : 0

                window.addEventListener('resize', () => {
                    screenWidth.value = window.innerWidth
                })

                clientTariffs.value = (await getAllClientTariffsByClientId(props.clientId)).data
                console.log('clientTariffs.value ------ ', clientTariffs.value)
            })

            watch(() => props.trainingsAttendedList, () => {
                percentTrainingsAttended.value = props.trainingsAttendedList.length * 100 / (props.trainingsAttendedList.length + props.trainingsMissedList.length)
                percentTrainingsAttended.value = percentTrainingsAttended.value ? percentTrainingsAttended.value : 0
            })

            const deleteTarriff = async (tariffId) => {
                await deleteClientTariffById(tariffId)
                clientTariffs.value = (await getAllClientTariffsByClientId(props.clientId)).data
                console.log('clientTariffs.value ------ ', clientTariffs.value)
                sliderKey.value = new Date().getTime()
                tariffDeletionFlag.value = false
            }

            return {
                percentTrainingsAttended,
                trainingsPlanedList,
                screenWidth,
                clientTariffs,
                deleteTarriff,
                updateTarriffDate,
                sliderKey,
                tariffDeletionFlag,
                tariffForDelete
            }
        },

        components: {
            CircleProgress,
            Carousel,
            Slide,
            Pagination,
            Navigation,
            TrainingCalendar,
            TariffCard,
            DeleteTariffForm
        },
    }
</script>
