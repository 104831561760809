<template>
    <div @click="$emit('close')" class="fixed h-full w-full"></div>
    <div class="fixed shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] w-[470px] h-[328px] flex flex-col z-[9999] justify-between top-[50%] translate-x-[-60%] left-[50%] translate-y-[-50%] bg-white2 p-4 rounded-[22px] mt-[-20px]">
        <div class="mt-[25px]">
            <div class="text-center mb-3 text-red-atten text-[16px]">підтвердіть видалення тарифу</div>
            <div class="text-center mb-3 text-gray-emb w-[60%] text-[13px] mx-auto">якщо бажаєте відмінити видалення - натисніть на пусту область</div>
        </div>
        <div class="flex justify-between w-[355px] mx-auto mb-[41px]">
            <button @click="deleteTariff" class="bg-[#990D35] w-full h-[44px] hover:bg-opacity-90 text-white px-4 py-1 rounded-[9px]">Видалити</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'delete-tariff-form',

        props: {
            tariffId: {
                type: Object,
                required: true
            }
        },

        setup(props, { emit }) {
            const deleteTariff = () => {
                emit('acceptDeleteTariff', props.tariffId)
            }

            return {
                deleteTariff
            }
        }
    }
</script>
