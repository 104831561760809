<template>
    <div @click="$emit('close')" class="fixed z-[999] w-full h-[100vh]"></div>
    <div class="absolute ml-[128px] w-[470px] pb-[30px] bg-white2 rounded-[22px] top-[50%] px-[44px] translate-y-[-50%] left-[50%] translate-x-[-50%] z-[1200]">
        <div class="bg-[#9A8C98] mt-[28px] bg-opacity-30 flex items-center justify-between  border-[#9A8C98] border-[1px] rounded-[20px] w-full px-[15px] py-[17px]">
            <div class="flex">
                <div class="flex items-center">
                    <div class="w-[35px] h-[35px] rounded-full overflow-hidden border-[1px] border-[#525A60]">
                        <img v-if="currentClient?.photo" :src="apiURL + 'static/' + currentClient?.photo">
                        <div v-else class="h-full w-full flex justify-center items-center text-gray-emb bg-gray-emb bg-opacity-20">{{ '?' }}</div>
                    </div>
                    <div>
                        <div class="ml-[10px] text-[#525A60] font-bold">{{ currentClient ? currentClient.name + ' ' + currentClient.surname : 'Не знайдено' }}</div>
                    </div>
                </div>
            </div>
            <div class="flex"></div>
        </div>

        <div>
            <!-- <carousel class=" select-none" :items-to-show="1" :wrapAround="false">
                <slide 
                    v-for="tariff in currentClientsTariffs"
                    :key="1" 
                >
                    <div>
                        <TariffCard class="scale-75" :tariff="tariff"/>
                    </div>
                </slide>


            </carousel> -->

            <carousel 
                :items-to-show="1.4" 
                class="w-full"
                :navigateTo="defaultCarouselValue"
                :navigationEnabled="true"
                :paginationEnabled="false"   
                v-if="currentClientsTariffs.length > 0" 
                v-model="defaultCarouselValue"
            >
                <slide 
                    v-for="(tariff, id) in currentClientsTariffs"
                    :key="id" 
                    :class="{
                        'carousel__slide--active' : id === 1,
                    }"
                >
                    <div>
                        <TariffCard v-if="tariff" class="scale-[0.75]" :tariff="tariff"/>
                        <div v-else class="scale-[0.65]" ></div>
                    </div>
                </slide>

                <!-- <template #addons>
                    <pagination ref="paginationSliderRef" :navigate-to="2" />
                </template> -->
            </carousel>

            <div v-else>
                <div class="text-[#990D35] font-bold text-center mt-[20px]">Клієнт не має абонементів для цього тренування</div>
            </div>
            <div v-if="clientAvalaibelBooks <= 0 && currentClient">
                <div class="text-[#990D35] font-bold text-center mt-[20px]">Кількість можливих записів за наявним тарифом у клієнта максимальна</div>
            </div>
            
        </div>

        <div class="mt-[20px]">
            <input v-model="searchQuery" class="bg-[#9A8C98] px-[14px] py-[12px] rounded-[10px] border-[1px] border-[#9A8C98] bg-opacity-30 w-[100%]" placeholder="номер телефону або ім'я" />
        </div>
        
        <button :disabled="currentClientsTariffs.length == 0 || clientAvalaibelBooks <= 0 || blockAddButton" @click="() => {blockAddButton = true; $emit('addClient', {client: currentClient, tariffs: currentClientsTariffs, currentId: defaultCarouselValue})}" class="w-[100%] flex justify-center disabled:opacity-60 mx-auto items-center rounded-[9px] mt-[20px] cursor-pointer font-bold h-[44px] bg-gray-emb text-white">
            <div>Додати до тренування</div>
        </button>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { getAllStudents, getAllClientTariffsByClientId, getClientsTrainingByClientId, getAllTariffsAndGroups } from '@/services/apiRequests'
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
    import TariffCard from './TariffCard.vue'

    export default {
        name: 'add-student-view',

        props: {
            training: {
                type: Object,
                default: null
            }
        },

        setup(props, { emit }) {
            const currentClient = ref(null)
            const searchQuery = ref('')
            const apiURL = process.env.VUE_APP_API_URL
            const currentClientsTariffs = ref([]) 

            const clientAvalaibelBooks = ref([])
            const clientBooks = ref([])

            const allTarriffsGroups = ref([])
            const currentGroupTarriffs = ref([])
            const currentTarriffGroup = ref(null)

            const defaultCarouselValue = ref(1)

            const blockAddButton = ref(false)

            onMounted(async () => {
                allTarriffsGroups.value = (await getAllTariffsAndGroups()).data
                currentTarriffGroup.value = allTarriffsGroups.value.filter(group => {
                    return group.group_id === props.training.tariff_group_id
                })[0]
                console.log('currentTarriffGroup.value', currentTarriffGroup.value)
                currentGroupTarriffs.value = currentTarriffGroup.value.tariffs
            
            })

            watch(searchQuery, async () => {
                const result = await getAllStudents(20, 0, 'asc', searchQuery.value, null, null, ['active'])
                console.log(result)
                currentClient.value = result.data[0]
                currentClientsTariffs.value = (await getAllClientTariffsByClientId(currentClient.value.student_id)).data
                if (!props.training.superposition) {
                    currentClientsTariffs.value = currentClientsTariffs.value.filter(tariff => {
                        return tariff.tarrif_group_id === props.training.tariff_group_id
                    })
                } else {
                    currentClientsTariffs.value = currentClientsTariffs.value
                }

                clientBooks.value = (await getClientsTrainingByClientId(currentClient.value.student_id)).data

                let clientBooksWithCurrentTarriffGroup = clientBooks.value.filter(book => {
                    console.log('111', currentGroupTarriffs.value.find(tarriff => {
                        console.log('tarriff.tariff_id', tarriff.tariff_id)
                        console.log('book.tariff_id', book.tariff_id)
                        return tarriff.tariff_id === book.tariff_id
                    }))
                    return currentGroupTarriffs.value.find(tarriff => {
                        return tarriff.tariff_id === book.tariff_id
                    })
                })

                console.log('clientBooksWithCurrentTarriffGroup', clientBooksWithCurrentTarriffGroup)

                clientBooksWithCurrentTarriffGroup = clientBooksWithCurrentTarriffGroup.filter(book => {
                    return book.status === 'planed'
                })

                clientAvalaibelBooks.value = currentClientsTariffs.value.reduce((acc, curr) => {
                    return acc + curr.training_remains
                }, 0)

                clientAvalaibelBooks.value = clientAvalaibelBooks.value - clientBooksWithCurrentTarriffGroup.length

                console.log('clientAvalaibelBooks', clientAvalaibelBooks.value)
            })



            return {
                currentClient,
                searchQuery,
                apiURL,
                currentClientsTariffs,
                clientAvalaibelBooks,
                defaultCarouselValue,
                blockAddButton
            }
        },

        components: {
            Carousel, Slide, Pagination, Navigation, TariffCard
        }
    }
</script>

<style lang="scss" scoped>

</style>