<template>
    <div @click="$emit('close')" class="backdrop-blur-[7px] fixed z-[999] w-full h-[100vh]"></div>
    <div class="absolute ml-[128px] w-[468px] scale-[1.15] h-[468px] bg-white border-[1px] border-gray-emb rounded-[22px] top-[50%] px-[44px] translate-y-[-50%] left-[50%] translate-x-[-50%] z-[1200]">
        <div class="text-[#525A60] font-semibold border-b-[1px] border-[#525A60] text-center text-[20px] pb-[9px] mt-[31px]">
            {{parseDate()}}
        </div>

        <!-- <div class="bg-[#9A8C98] mt-[28px] bg-opacity-30 flex items-center justify-between  border-[#9A8C98] border-[1px] rounded-[20px] w-full px-[15px] py-[17px]">
            <div class="flex">
                <div class="flex items-center">
                    <div class="w-[35px] h-[35px] rounded-full overflow-hidden border-[1px] border-[#525A60]">
                        <img v-if="selectedTrainer?.photo" :src="apiURL + 'static/' + selectedTrainer?.photo">
                        <div v-else class="h-full w-full flex justify-center items-center text-gray-emb bg-gray-emb bg-opacity-20">{{ selectedTrainer ? selectedTrainer?.name[0] + selectedTrainer?.surname[0] : '' }}</div>
                    </div>
                    <div>
                        <div class="ml-[10px] text-[#525A60] font-bold">{{ selectedTrainer ? selectedTrainer?.name + ' ' + selectedTrainer?.surname : 'Не задано' }}</div>
                        <div class="flex ml-[10px] items-center" v-if="startTime && endTime">
                            <div class="text-[14px] relative top-[1px]">{{startTime}}</div>
                            <div class="mx-[10px]">
                                <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 11.2113L5.30138 6.87519C5.63094 6.54297 5.79572 6.37686 5.79572 6.17093C5.79572 5.965 5.63094 5.79889 5.30138 5.46667L1 1.13055" stroke="#22223B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div class="text-[14px] relative top-[1px]">{{endTime}}</div>
                        </div>
                    </div>
                </div>
                <div class="ml-[14px]" v-if="selectedGroup?.group_name && trainingType">
                    <div class="text-[#525A60] lowercase font-bold truncate">{{ selectedGroup?.group_name }}</div>
                    <div class="text-[#525A60]">{{ parseTrainingType(trainingType) }}</div>
                </div>
                <div class="ml-[14px] w-[88px] bg-gray-emb px-[5px] rounded-[4px] mt-[26px]" v-if="selectedLocation?.name">
                    <div class="text-white text-[11px] w-full truncate">{{ selectedLocation?.name }}</div>
                </div>
            </div>
            <div class="flex">
                <div v-if="selectedGroup?.group_max_clients" class="text-[#6BA292]">
                    0/{{maxClientsValue}}
                </div>
                <div>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="11" cy="11" r="11" fill="#6BA292"/>
                    </svg>
                </div>
            </div>
        </div> -->

        <SquircleComponent
            class="mt-[22px] relative bg-[#DBEBEA] mx-auto"
            :smooth="110" 
            :radius="20" 
            :width="382" 
            :height="68" 
            :outlineWidth="0"
        >
                <div class="flex w-full text-[#4A4E69] absolute top-[50%] translate-y-[-50%] items-center ml-[10px] mr-[16px] ">
                    <div class="w-full flex items-center h-full">
                        <div class="w-[44px] h-[44px] relative rounded-full overflow-hidden border-[2px] border-[#525A60]" style="box-sizing: border-box;">
                            <img v-if="selectedTrainer?.photo" :src="apiURL + 'static/' + selectedTrainer?.photo" class="w-full h-full bg-white ">
                            <div v-else class="h-full w-full flex justify-center items-center text-gray-emb bg-gray-emb bg-opacity-20">{{ selectedTrainer ? selectedTrainer?.name[0] + selectedTrainer?.surname[0] : '' }}</div>
                        </div>
                        <div class="w-max ml-[5px]">
                            <div class="w-full border-opacity-20">
                                <div class="flex w-full items-center">
                                    <div class="font-bold gap-[20px] flex flex-col relative text-[16px] text-gray-emb w-[37%]">
                                        <div class="text-[13px] leading-[19px]">{{ selectedTrainer?.surname }}<br>{{ selectedTrainer?.name  }}</div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="ml-[4px] flex flex-col justify-between gap-[5px]">
                            <!-- <div class="bg-[#525A60CC] text-white w-[72px] truncate px-[4px] rounded-[4px] text-[12px]">{{ selectedLocation?.name }}</div> -->
                            <div class="w-[72px] h-[14px] rounded-[4px] bg-[#686D72] flex items-center">
                                <span class="font-black text-white text-[9px] w-[64px] ml-[5px] mt-[2px]  avenir-black">{{ selectedLocation?.name }}</span>
                            </div>
                            
                            <!-- <div class="bg-[#525A60CC] text-white w-[72px] truncate px-[4px] rounded-[4px] text-[11px] flex justify-center items-center">
                                <div v-if="startTime && endTime" class="text-[11px]">{{startTime}}</div>
                                <div v-if="startTime && endTime" class="px-[2px] relative top-[0.5px]">
                                    <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.618838 6.49194L3.25992 4.29104C3.5366 4.06048 3.67494 3.94519 3.67494 3.79353C3.67494 3.64187 3.5366 3.52658 3.25992 3.29602L0.618838 1.09512" stroke="white" stroke-width="1.07937" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                </div>
                                <div v-if="startTime && endTime" class="text-[11px]">{{endTime}}</div>
                            </div> -->

                            <div class="w-[72px] h-[14px] rounded-[4px] bg-[#686D72] flex items-center">
                                <div class="w-[80px] ml-[5px] mr-[2px] leading-[0] h-[0] flex items-center">
                                    <div class="font-black flex justify-between w-full items-center text-white text-[9px] mr-[3px] carisma-black mt-[2px]">
                                        <div v-if="startTime && endTime">{{startTime}}</div> 
                                        <div v-if="startTime && endTime" class="relative bottom-[1px]">
                                            <svg width="4" height="7" viewBox="0 0 4 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 6L2.67635 3.90456C2.83154 3.71058 2.90913 3.61359 2.90913 3.5C2.90913 3.38641 2.83154 3.28942 2.67635 3.09544L1 1" stroke="white" stroke-width="1.07937" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        <div v-if="startTime && endTime">{{endTime}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ml-[8px] flex flex-col justify-between gap-[5px]">
                            <!-- <div class="bg-[#525A60CC] text-white w-[88px] h-[14px] truncate px-[4px] rounded-[4px] text-[9px] py-[1px] font-black">{{ selectedGroup?.group_name ?? 'Personal/Split' }}</div> -->
                            <div class="w-[88px] h-[14px] rounded-[4px] bg-[#686D72] flex items-center">
                                <span class="font-black text-white text-[9px] w-[60px] ml-[5px] mt-[2px]  avenir-black">{{ selectedGroup?.group_name ?? 'Personal/Split' }}</span>
                            </div>
                            <!-- <div v-if="selectedGroup?.group_name || selectedGroupId == 'superposition'" class="bg-[#525A60CC] text-white w-[88px] h-[14px] truncate px-[4px] rounded-[4px] text-[11px]">
                                {{ parseTrainingType(trainingType) }}
                            </div> -->
                            <div v-if="(selectedGroup?.group_name || selectedGroupId == 'superposition') && startTime && endTime && selectedLocation?.name" class="w-[88px] h-[14px] rounded-[4px] bg-[#686D72] flex items-center">
                                <span class="w-[80px] ml-[5px] leading-[0] h-[0] flex items-center">
                                    <span class="font-black text-white text-[9px] mr-[3px] carisma-black mt-[2px]">{{ parseTrainingType(trainingType) }}</span>
                                    <span v-if="parseTrainingSubtype(trainingType)" class="font-light text-white text-[9px] avenir-book">({{ parseTrainingSubtype(trainingType) }})</span>
                                </span>
                            </div>
                        </div>
                        <div class="flex absolute right-[24px]">
                            <div 
                                class="truncate text-[#6BA292] font-[900] mr-[6px] text-[14px] text-start"   
                            >0/{{ maxClientsValue }}</div>
                            <div 
                                class="h-[16px] w-[16px] border-[2px] rounded-full bg-[#6BA292] border-[#6BA292]"
                            ></div>
                        </div>
                    </div>
                </div>
        </SquircleComponent>

        <div class="mt-[32px]">
            <div class="flex gap-[22px]">
                <div class="">
                    <select v-model="selectedTrainerId" class="w-[180px] select-icon h-[32px] bg-[#F8F8F8] text-[13px] text-gray-emb rounded-[8px] px-[10px] placeholder:text-gray-emb placeholder:opacity-60" placeholder="оберіть тренера">
                        <option :value="null" disabled>оберіть тренера</option>
                        <option v-for="trainer in allTrainers" :value="trainer.trainer_id">{{ trainer.name + ' ' + trainer.surname }}</option>
                    </select>
                </div>
                <div class=" flex items-center ">
                    <input v-model="startTime" type="time" class="input-icon w-[78px] h-[34px] text-[13px] bg-[#F8F8F8] rounded-[8px] px-[7px] text-gray-emb placeholder:text-gray-emb placeholder:opacity-60" placeholder="11.00" />
                    <div class="mx-[12px]"> 
                        <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 11L4.50024 6.6247C4.73987 6.32516 4.85969 6.17539 4.85969 6C4.85969 5.82461 4.73987 5.67484 4.50024 5.37531L0.999999 1" stroke="#9A8C98" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                    </div>
                    <input v-model="endTime" type="time" class="input-icon w-[78px] h-[34px] text-[13px] bg-[#F8F8F8] rounded-[8px] px-[7px] text-gray-emb placeholder:text-gray-emb placeholder:opacity-60" placeholder="12.00" />

                </div>
            </div>
            <div class="flex gap-[22px] mt-[18px]">
                <div class="">
                    <select v-model="selectedLocationId" class="w-[180px] select-icon h-[32px] bg-[#F8F8F8] text-[13px] text-gray-emb rounded-[8px] px-[10px] placeholder:text-gray-emb placeholder:opacity-60">
                        <option :value="null" disabled>оберіть зал</option>
                        <option v-for="location in allLocations" :value="location.location_id">{{ location.name }}</option>
                    </select>
                </div>
                <div class="">
                    <select v-model="trainingType" class="w-[180px] select-icon h-[32px] bg-[#F8F8F8] text-[13px] text-gray-emb rounded-[8px] px-[10px] placeholder:text-gray-emb placeholder:opacity-60">
                        <option :value="null" disabled>вид тренування</option>
                        <option v-for="typeTrain in trainingTypes" :value="typeTrain.value">{{ typeTrain.label }}</option>
                    </select>
                </div>
            </div>
            <div class="flex gap-[22px] mt-[18px]">
                <div class="">
                    <select v-model="selectedGroupId" class="w-[180px] select-icon h-[32px] bg-[#F8F8F8] text-[13px] text-gray-emb rounded-[8px] px-[10px] placeholder:text-gray-emb placeholder:opacity-60">
                        <option :value="null" disabled>тип абонемента</option>
                        <option v-for="group in allGroups" :value="group.group_id" >{{ group.group_name }}</option>
                        <option value="superposition">PERSONAL/SPLIT</option>
                    </select>
                </div>
                <div class="">
                    <select v-model="maxClientsValue" class="w-[180px] select-icon h-[32px] bg-[#F8F8F8] text-[13px] text-gray-emb rounded-[8px] px-[10px] placeholder:text-gray-emb placeholder:opacity-60">
                        <option :value="0" disabled>макс. к-сть клієнтів</option>
                        <option v-for="val in 6" :value="val">{{ val }}</option>
                    </select>
                </div>
            </div>
        </div>


        <div @click="saveTraining" class="w-[382px] flex justify-center mx-auto text-[13px] items-center rounded-[9px] mt-[54px] cursor-pointer font-semibold h-[44px] bg-green-emb text-gray-emb">
            <div>Зберегти</div>
        </div>

        <!-- <div class="w-[88px] h-[14px] rounded-[4px] bg-[#686D72] flex items-center mt-[20px]">
            <span class="font-black text-white text-[9px] w-[60px] ml-[5px] mt-[2px]  avenir-black">Personal/Split</span>
        </div>
        <div class="w-[88px] h-[14px] mt-[10px] rounded-[4px] bg-[#686D72] flex items-center">
            <span class="w-[80px] ml-[5px] leading-[0] h-[0] flex items-center">
                <span class="font-black text-white text-[9px] mr-[3px] carisma-black mt-[2px]">Пілатес</span>
                <span class="font-light text-white text-[9px] avenir-book">(reformer)</span>
            </span>
        </div>
        <div class="w-[72px] h-[14px] rounded-[4px] bg-[#686D72] flex items-center mt-[20px]">
            <span class="font-black text-white text-[9px] w-[64px] ml-[5px] mt-[2px]  avenir-black">Room 1</span>
        </div>
        <div class="w-[72px] h-[14px] mt-[10px] rounded-[4px] bg-[#686D72] flex items-center">
            <div class="w-[80px] ml-[5px] mr-[2px] leading-[0] h-[0] flex items-center">
                <div class="font-black flex justify-between w-full items-center text-white text-[9px] mr-[3px] carisma-black mt-[2px]">
                    <div>12:30</div> 
                    <div class="relative bottom-[1px]">
                        <svg width="4" height="7" viewBox="0 0 4 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 6L2.67635 3.90456C2.83154 3.71058 2.90913 3.61359 2.90913 3.5C2.90913 3.38641 2.83154 3.28942 2.67635 3.09544L1 1" stroke="white" stroke-width="1.07937" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div>14:00</div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
    import { uuid } from 'vue3-uuid'
    import { ref, computed, reactive, watch, onBeforeMount, onMounted } from 'vue'
    import { getAllLocations, getAllGroups, addNewTraining, getAllTrainers, getAllTariffsAndGroups } from '@/services/apiRequests'
    import MultiSelect from './MultiSelect.vue'
    import FileView from '../ui/FileView.vue'
    import ImageViewer from '../ui/ImageViewer.vue'
    import SquircleComponent from './SquircleComponent.vue'

    export default {
        name: 'new-training-form',

        props: {
            date: {
                type: Date,
                default: '2022-12-12',
                required: true
            }
        },

        setup(props, { emit }) {
            const allTrainers = ref([])
            const allLocations = ref([])
            const trainingType = ref(null)
            const allGroups = ref([])
            const showTariffs = ref([]) 

            const selectedTrainer = ref(null)
            const selectedLocation = ref(null)
            const selectedGroup = ref(null)
            const selectedTariffId = ref(null)

            const selectedTrainerId = ref(null)
            const selectedLocationId = ref(null)
            const selectedGroupId = ref(null)
            const selectedTariff = ref(null)

            const trainingTypes = ref([
                { value: 'yoga', label: 'йога' },
                { value: 'barre', label: 'барре' },
                { value: 'pilates_ref', label: 'пілатес/реформер' },
                { value: 'pilates_mat', label: 'пілатес/мат' },
                { value: 'pilates_preg', label: 'пілатес для вагітних' },
                { value: 'straching', label: 'стречінг' },

            ])

            const maxClientsValue = ref(0)

            const startTime = ref('')
            const endTime = ref('')

            const apiURL = process.env.VUE_APP_API_URL

            const parseTrainingType = (type) => {
                switch (type) {
                    case 'yoga':
                        return 'йога'
                    case 'barre':
                        return 'барре'
                    case 'pilates_ref':
                        return 'пілатес'
                    case 'pilates_mat':
                        return 'пілатес'
                    case 'straching':
                        return 'стретчинг'
                    case 'pilates_preg':
                        return 'пілатес для вагітних'
                }
            }

            const parseTrainingSubtype = (type) => {
                switch (type) {
                    case 'pilates_ref':
                        return 'reformer'
                    case 'pilates_mat':
                        return 'mat'

                    default:
                        return null
                }
            }

            watch(selectedGroup, (newVal) => {
                if (newVal) {
                    showTariffs.value = newVal.tariffs
                }
            })

            watch(selectedGroupId, (newVal) => {
                if (newVal) {
                    selectedGroup.value = allGroups.value.find(group => group.group_id == newVal)
                }
            })

            watch(selectedTariffId, (newVal) => {
                if (newVal) {
                    selectedTariff.value = showTariffs.value.find(tariff => tariff.tariff_id == newVal)
                }
            })

            watch(selectedTrainerId, (newVal) => {
                if (newVal) {
                    selectedTrainer.value = allTrainers.value.find(trainer => trainer.trainer_id == newVal)
                }
            })

            watch(selectedLocationId, (newVal) => {
                if (newVal) {
                    selectedLocation.value = allLocations.value.find(location => location.location_id == newVal)
                }
            })

            const parseDate = () => {
                const dateClone = new Date(props.date)
                console.log('props.date ------- ', props.date)
                const parsedDate = dateClone.toISOString()
                return parsedDate.split('T')[0].split('-').reverse().join('.')
            }

            onMounted(async () => {
                const groupsData = await getAllTariffsAndGroups()

                allGroups.value = groupsData.data.map(group => {
                    return {
                        group_id: group.group_id,
                        group_name: group.group_name,
                        group_max_clients: group.group_max_clients,
                        tariffs: group?.tariffs?.map(tariff => {
                            return {
                                tariff_id: tariff.tariff_id,
                                tariff_name: tariff.tariff_name,
                                tariff_training_count: tariff.tariff_training_count,
                                tariff_days: tariff.tariff_days,
                                tariff_price: tariff.tariff_price,
                                tariff_description: tariff.tariff_description,
                                tariff_color: tariff.tariff_color
                            }
                        }) ?? []
                    }
                })

                showTariffs.value = allGroups.value[0].tariffs

                const trainersData = await getAllTrainers()
                allTrainers.value = trainersData.data

                const locationsData = await getAllLocations()
                allLocations.value = locationsData.data

                trainingType.value = null
            })

            const saveTraining = async () => {                
                if (selectedGroupId.value == 'superposition') {
                    const newTrainingSuperposition = {
                        training_id: uuid.v4(),
                        date: props.date.toISOString(),
                        start_time: startTime.value,
                        end_time: endTime.value,
                        location_id: selectedLocationId.value,
                        location_label: selectedLocation.value.name,
                        status: 'planed',
                        type: trainingType.value,
                        trainer_id: selectedTrainerId.value,
                        trainer_label: selectedTrainer.value.name + ' ' + selectedTrainer.value.surname,
                        trainer_photo: selectedTrainer.value.photo,
                        tariff_group_id: null,
                        tariff_id: '',
                        tariff_name: '',
                        tariff_group_name: null,
                        max_clients: 1,
                        superposition: true
                    }
                    await addNewTraining(newTrainingSuperposition)
                } else {
                    const newTrainingNormal = {
                        training_id: uuid.v4(),
                        date: props.date.toISOString(),
                        start_time: startTime.value,
                        end_time: endTime.value,
                        location_id: selectedLocationId.value,
                        location_label: selectedLocation.value.name,
                        status: 'planed',
                        type: trainingType.value,
                        trainer_id: selectedTrainerId.value,
                        trainer_label: selectedTrainer.value.name + ' ' + selectedTrainer.value.surname,
                        trainer_photo: selectedTrainer.value.photo,
                        tariff_group_id: selectedGroupId.value,
                        tariff_id: '',
                        tariff_name: '',
                        tariff_group_name: selectedGroup.value.group_name,
                        max_clients: maxClientsValue.value
                    }
                    await addNewTraining(newTrainingNormal)
                }
                
                // await addNewTraining(newTraining)
                emit('updateTrainings')
                emit('close')
            }
            

            return {
                allTrainers,
                allLocations,
                trainingType,
                allGroups,
                showTariffs,
                parseDate,
                selectedTrainer,
                selectedLocation,
                selectedGroup,
                selectedTariffId,
                selectedTrainerId,
                selectedLocationId,
                selectedGroupId,
                selectedTariff,
                saveTraining,
                startTime,
                endTime,
                parseTrainingType,
                apiURL,
                maxClientsValue,
                parseTrainingSubtype,
                trainingTypes
            }
        },

        components: {
            MultiSelect,
            FileView,
            ImageViewer,
            SquircleComponent
        }
    }
</script>

<style lang="scss" scoped>
    @font-face {
        font-family: 'Fixel';
        src: url('../../../assets/fonts/FixelDisplay/FixelDisplay-Regular.ttf');
    }

    @font-face {
        font-family: 'Avenir';
        src: url('../../../assets/fonts/avenirnextcyr-regular.ttf');
    }

    @font-face {
        font-family: 'Avenir-Black';
        src: url('../../../assets/fonts/AvenirLTStd-Black.otf');
    }

    @font-face {
        font-family: 'Carisma-Black';
        src: url('../../../assets/fonts/Carisma/CarismaGothic-600DemiBold.ttf');
    }

    .window {
        font-family: 'Fixel';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .avenir-book {
        font-family: 'Avenir';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .avenir-black {
        font-family: 'Avenir-Black';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .carisma-black {
        font-family: 'Carisma-Black';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .select-icon {
        -webkit-appearance: none;
        -moz-appearance: none;

        background-image: url("@/assets/icons/select_icon.svg");
        background-repeat: no-repeat;
        background-position-x: 93%;
        background-position-y: 55%;
        cursor: pointer;
        outline: none;
        border: 1px solid #C3DDDC;
    }

    .select-icon:focus {
        -webkit-appearance: none;
        -moz-appearance: none;

        background-image: url("@/assets/icons/select_icon_focus.svg");
        background-repeat: no-repeat;
        background-position-x: 93%;
        background-position-y: 55%;
        outline: none;
        border: 1px solid #BF637D;
    }

    .input-icon {
        -webkit-appearance: none;
        -moz-appearance: none;

        background-image: none;
        background-repeat: no-repeat;
        background-position-x: 93%;
        background-position-y: 55%;
        cursor: pointer;
        outline: none;
        border: 1px solid #C3DDDC;
        text-align: center;
    }

    .input-icon::-webkit-calendar-picker-indicator {

        background-image: none;
        width: 0;
        display: none;
    }

    .input-icon:focus {
        -webkit-appearance: none;
        -moz-appearance: none;

        background-image: none;
        background-repeat: no-repeat;
        background-position-x: 93%;
        background-position-y: 55%;
        outline: none;
        border: 1px solid #BF637D;
    }
</style>

    